import * as React from 'react'

import { cn } from '@/lib/utils'
import { XIcon } from 'lucide-react'
import { Button } from '~/components/ui/button'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onClear?: () => void
  error?: string | null
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, onClear, error, ...props }, ref) => {
  const hasValue = !!props.value
  const isInvalid = !!error

  return (
    <div className="relative w-full">
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm text-right ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          className,
          {
            'border-red-400 !ring-red-400': isInvalid,
          },
        )}
        ref={ref}
        {...props}
      />
      {hasValue && onClear && (
        <Button
          variant="ghost"
          size="icon"
          className="absolute left-2 top-2 w-6 h-6 p-1 text-gray-600"
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            onClear()
          }}
        >
          <XIcon />
        </Button>
      )}
      {isInvalid && <span className="text-red-400 text-sm py-1">{error}</span>}
    </div>
  )
})
Input.displayName = 'Input'

export { Input }
